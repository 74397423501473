/* Fonts */

@font-face {
  font-family: "Raleway";
  font-weight: 100;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Thin.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 200;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 300;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 400;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 500;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 600;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 700;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 800;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Raleway";
  font-weight: 900;
  src: local("Raleway"),
    url(./theme/fonts/Raleway/Raleway-Black.ttf) format("truetype");
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Raleway, Arial, sans-serif;
  min-width: 320px;
  overflow-x: hidden;
  color: #000;
}

/* Custom loader */
.loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid #2e666d;
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s linear infinite;
  -webkit-animation: spin 1s linear infinite;
}

/* .select-time-dropdown {
  position: relative;
  display: inline-block;
  padding: 40px 48% 0 0;
}

.select-time-dropdown-content {
  overflow: scroll;
  max-height: 200px;
  display: none;
  position: absolute;
  min-width: 209%;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
} */

.select-time-dropdown:hover .select-time-dropdown-content {
  display: block;
}

/* .select-time-element {
  color: #095c66;
  padding: 20px 0 0 20px;
  vertical-align: baseline;
}

.select-time-element:hover {
  background-color: #d5e0e2;
} */

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 0.9em;
}

::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(
    180deg,
    #095c66 0.02%,
    #209aa2 95.69%
  ); /* color of the scroll thumb */
  border-radius: 11px; /* roundness of the scroll thumb */
  border: 3px solid #fff; /* creates padding around scroll thumb */
}

/* Toast notification */

.Toastify__toast--success {
  background: #bedad7;
  border-radius: 16px;
  color: #2e666d;
  /* H4 */
  font-family: "Raleway";
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

.Toastify__toast--error {
  background: #f76d7d;
  border-radius: 16px;
  color: #fff;
  /* H4 */
  font-family: "Raleway";
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}

/* modal reactstrap */

.reactstrap-modal-full-size {
  min-width: 100%;
  min-height: 100vh;
  margin: 0;
  background-color: #d5e0e2;
}

.reactstrap-modal-full-size > .modal-content {
  min-height: 100vh;
  overflow-x: auto;
  background-color: #d5e0e2;
}
